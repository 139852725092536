<template>
  <div class="d-flex justify-content-center mr-2 ml-2">
    <button
      class="button-filter"
      @click="showFilter"
    >
      <div class="mr-1 mb-1">
        <img
          src="@/assets/filter.svg"
          alt="Фильтр"
        >
      </div>
      <div style="color: #222A34">
        Фильтр
      </div>
      <div
        v-if="getCountFiltersUsed !== null"
        class="count-filter"
      >
        {{ getCountFiltersUsed }}
      </div>
    </button>
    <button
      class="fuel-type-button"
      :class="{ active: selectedFuelTypes.includes('АИ-98') }"
      @click="toggleFuelType('АИ-98')"
    >
      АИ-98
    </button>
    <button
      class="fuel-type-button"
      :class="{ active: selectedFuelTypes.includes('АИ-92') }"
      @click="toggleFuelType('АИ-92')"
    >
      АИ-92
    </button>
    <button
      class="fuel-type-button"
      :class="{ active: selectedFuelTypes.includes('АИ-95') }"
      @click="toggleFuelType('АИ-95')"
    >
      АИ-95
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonFilter",
  props: {
    selectedFuelTypes: {
      type: Array,
      default: () => [],
    },
    isFilterShow: {
      type: Boolean,
      default: null,
    },
    selectedStationBrands: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getCountFiltersUsed() {
      return this.selectedFuelTypes.length + this.selectedStationBrands.length || null;
    }
  },
  methods: {
    toggleFuelType(fuelType) {
      const updated = this.selectedFuelTypes.includes(fuelType)
        ? this.selectedFuelTypes.filter(type => type !== fuelType)
        : [...this.selectedFuelTypes, fuelType];

      this.$emit('selectedFuelTypes', updated);
    },
    showFilter() {
      this.$emit('isFilterShow', true);
    }
  }
}
</script>

<style scoped>
  .button-filter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100px;
    border-radius: 12px;
    background: white;
    z-index: 1001;
    border: 0 solid;
    box-shadow: 0 1px 20px 0 #0000000D;
  }

  .count-filter {
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    color: white;
    background: #00A3FF;
    font-size: 12px;
  }

  .fuel-type-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 80px;
    border-radius: 12px;
    background: white;
    margin-left: 8px;
    z-index: 1001;
    box-shadow: 0 1px 20px 0 #0000000D;
    cursor: pointer;
    border: 0 solid;
    color: #222A34
  }

  .fuel-type-button.active {
    background: #00a3ff;
    color: white;
  }
</style>
