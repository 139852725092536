import axios from "axios";

// const getters = {
//   isAuthenticated: state => !!state.jwt,
// };
const getters = {
  isAuthenticated: () => true,
};
const mutations = {
  SET_LOGIN: (state, payload) => {
    state.jwt = payload.jwt;
    localStorage.setItem('user-token', payload.jwt);
  },
};
const actions = {
  AUTH_REQUEST: async (context, payload) => {
    context.commit('SET_LOGIN', payload);
  },
  GET_CHECKSESSION: async (context, payload) => {
    const req = JSON.stringify(payload);
    let {data} = await axios.post('/fuel/check-session', req);
    //context.commit('SET_S', data);
    return data;

  },
};

export default {
  state: () => ({
    jwt: localStorage.getItem('user-token') || '',
  }),
  mutations,
  actions,
  getters
};
