import axios from "axios";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
};

const getters = {
  STATE_ORDER: state => state.stateOrder,
  STATE: state => state.state
};
const mutations = {
  SET_STATE_ORDER: (state, payload) => {
    state.stateorder = payload.status == 1 ? payload.state : state.stateOrder;
    state.state = payload;
  },
};
const actions = {
  GET_STATE_ORDER: async (context, payload) => {
    const req = JSON.stringify(payload);
    let {data} = await axios.get('/fuel/state-order/', req, config);
    context.commit('SET_STATE_ORDER', data);
    return data;
  },
  POST_STATE_ORDER: async (context, payload) => {
    const req = JSON.stringify(payload);
    let {data} = await axios.post('/fuel/state-order/', req, config);
    context.commit('SET_STATE_ORDER', data);
    return data;
  },
  RESET_STATE_ORDER: async (context) => {
    context.commit('SET_STATE_ORDER', {'status': 1, 'state': 'new'});
  }
};

export default {
  state: () => ({
    stateOrder: null,
    state: null,
  }),
  mutations,
  actions,
  getters
};
