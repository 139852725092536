import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import station from './modules/station'
import kolonki from './modules/kolonki'
import order from './modules/order'
import stateorder from './modules/stateorder'
//import setupInterceptors from './setupInterceptors'

Vue.use(Vuex);

import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + validToken();

function validToken() {
  return localStorage.getItem('user-token') || '';
}

//setupInterceptors();

export const store = new Vuex.Store({
    modules: {
      auth,
      station,
      kolonki,
      order,
      stateorder
    }
});
