<template>
  <div
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div
      class="filter-station"
      :class="{ open: isOpen, closed: !isOpen }"
    >
      <div class="filter-container">
        <div
          class="d-flex justify-content-center mb-3"
          style="height: 14px; width: 100%"
        >
          <img
            class="image-swipe"
            src="@/assets/swipe.svg"
            alt="Свайп"
          >
        </div>
        <div class="d-flex justify-content-between">
          <div class="filter-title">
            Фильтры
          </div>
          <button
            class="reset-button"
            @click="resetFilters"
          >
            Сбросить
          </button>
        </div>
        <div
          ref="swiper"
          class="swiper-container"
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
        >
          <div style="overflow-y: scroll; height: calc(100vh - 150px);">
            <div class="slide">
              <div class="fuel-type-container">
                <div class="title">
                  Топливо
                </div>
                <button
                  v-for="(fuel, f) in fuelType"
                  :key="f"
                  class="fuel-type-button"
                  :class="{ active: selectedFuelTypes.includes(fuel.name) }"
                  @click="toggleFuelType(fuel.name)"
                >
                  {{ fuel.name }}
                </button>
              </div>
            </div>
            <div class="slide">
              <div>
                <div class="title mt-5">
                  Бренд
                </div>
                <div
                  v-for="(stationBrand, sb) in fuelStationBrand"
                  :key="sb"
                  class="station-brand"
                >
                  <div class="d-flex align-items-center">
                    <img
                      :src="stationBrand.logo"
                      :alt="stationBrand.name"
                      class="logo-fuel-brand"
                    >
                    <div>{{ stationBrand.name }}</div>
                  </div>
                  <div class="select-fuel-brand">
                    <div class="checkbox-wrapper-31">
                      <input
                        :id="'checkbox-' + stationBrand.id"
                        :value="stationBrand.id"
                        type="checkbox"
                        :checked="selectedStationBrands.includes(stationBrand.id)"
                        @change="toggleBrandSelection(stationBrand.id)"
                      >
                      <svg viewBox="0 0 35.6 35.6">
                        <circle
                          class="background"
                          cx="17.8"
                          cy="17.8"
                          r="17.8"
                        />
                        <circle
                          class="stroke"
                          cx="17.8"
                          cy="17.8"
                          r="14.37"
                        />
                        <polyline
                          class="check"
                          points="11.78 18.12 15.55 22.23 25.17 12.87"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="button"
          @click="applyFilter"
        >
          Применить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterStation',
  props: {
    selectedFuelTypes: {
      type: Array,
      default: () => [],
    },
    selectedStationBrands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: true,
      isSwipe: false,
      touchStartY: 0,
      touchCurrentY: 0,
      fuelType: [
        { name: 'АИ-92' },
        { name: 'АИ-95' },
        { name: 'АИ-98' },
        { name: 'АИ-100' },
        { name: 'АИ-80' },
        { name: 'ДТ' },
        { name: 'Пропан' },
        { name: 'Метан' }
      ],
      fuelStationBrand: [
        {
          id: 1,
          name: "Сургутнефтегаз",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 2,
          name: "Лукойл",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 3,
          name: "Татнефть",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 4,
          name: "Газпром",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 5,
          name: "Роснефть",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 6,
          name: "Shell",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 7,
          name: "ExxonMobil",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        },
        {
          id: 8,
          name: "Total",
          // eslint-disable-next-line no-undef
          logo: require("@/assets/gasprom.svg"),
        }
      ],
      isFilterShow: false,
    }
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
      this.isSwipe = false;
    },
    handleTouchMove(event) {
      this.touchCurrentY = event.touches[0].clientY;
      if (Math.abs(this.touchStartY - this.touchCurrentY) > 10) {
        this.isSwipe = true;
      }
    },
    handleTouchEnd() {
      if (this.isSwipe) {
        const touchDiff = this.touchStartY - this.touchCurrentY;
        const threshold = 50;
        this.isOpen = touchDiff > threshold;
      }
    },
    toggleFuelType(fuelType) {
      const updated = this.selectedFuelTypes.includes(fuelType)
        ? this.selectedFuelTypes.filter(type => type !== fuelType)
        : [...this.selectedFuelTypes, fuelType];
      this.$emit('updateSelectedFuelTypes', updated);
    },
    toggleBrandSelection(brandId) {
      const updated = this.selectedStationBrands.includes(brandId)
        ? this.selectedStationBrands.filter(id => id !== brandId)
        : [...this.selectedStationBrands, brandId];
      this.$emit('updateSelectedStationBrands', updated);
    },
    resetFilters() {
      this.$emit('updateSelectedFuelTypes', []);
      this.$emit('updateSelectedStationBrands', []);
    },
    applyFilter() {
      this.updateFilterShow();
      this.$emit('updateSelectedFuelTypes', this.selectedFuelTypes);
      this.$emit('updateSelectedStationBrands', this.selectedStationBrands);
    },
    updateFilterShow() {
      this.$emit('updateFilterShow', this.isFilterShow);
    }
  }
}
</script>

<style scoped>
  .filter-station {
    position: fixed;
    background: white;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: top 0.3s ease-in-out;
    padding: 0 16px;
    margin: 0 auto;
  }

  .filter-station.open {
    top: 0;
  }

  .filter-station.closed {
    top: 90vh;
  }

  .filter-container {
    max-width: 360px;
    margin: 0 auto;
  }

  .image-swipe {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .filter-title {
    font-size: 24px;
    font-weight: 700;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .reset-button {
    background: none;
    color: #00A3FF;
    border: 0 solid;
  }

  .swiper-container {
    margin: 5px 0;
    padding: 35px 0 15px;
    height: calc(100vh - 160px);
    overflow-y: scroll;
  }

  .slide {
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    height: auto;
  }

  .fuel-type-button {
    align-items: center;
    justify-content: center;
    height: 40px;
    width: auto;
    border-radius: 12px;
    color: #222A34;
    background: #F4F5FA;
    z-index: 1001;
    box-shadow: 0 1px 20px 0 #0000000D;
    cursor: pointer;
    border: 0 solid;
    padding: 10px 16px;
    margin: 0 8px 8px 0;
  }

  .fuel-type-button.active {
    background: #00a3ff;
    color: white;
  }

  .station-brand {
    height: 60px;
    display: flex;
    justify-content: space-between;
  }

  .logo-fuel-brand {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .select-fuel-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
  }

  .button {
    position: absolute;
    padding: 0;
    border: none;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    color: white;
    background: #00A3FF;
    height: 52px;
    min-width: 350px;
    z-index: 1002;
    margin-top: 10px;
  }

  .checkbox-wrapper-31 {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
  }

  .checkbox-wrapper-31 input[type=checkbox] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-wrapper-31 svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  .checkbox-wrapper-31 .background {
    fill: #ccc;
    transition: ease all 0.6s;
  }

  .checkbox-wrapper-31 .stroke {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke-dashoffset: 100;
    stroke-dasharray: 100;
    transition: ease all 0.6s;
  }

  .checkbox-wrapper-31 .check {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
    stroke-dashoffset: 22;
    stroke-dasharray: 22;
    transition: ease all 0.6s;
  }

  .checkbox-wrapper-31 input[type=checkbox]:checked + svg .background {
    fill: #00A3FF;
  }

  .checkbox-wrapper-31 input[type=checkbox]:checked + svg .stroke {
    stroke-dashoffset: 0;
  }

  .checkbox-wrapper-31 input[type=checkbox]:checked + svg .check {
    stroke-dashoffset: 0;
  }
</style>
