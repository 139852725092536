<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
.ymap-container {
  width: 100vw;
  height: 100vh;
}
</style>
