import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from "./page/Main";
import Error from "./page/Error";

Vue.use(VueRouter);

// const urlSearchParams = new URLSearchParams(window.location.search);
// const params = Object.fromEntries(urlSearchParams.entries());

// удалить строку
const params = {
  token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJJRCI6NTA4NTEsInBob25lIjoiOTE3NTQyMjYyNyIsIlN0YXR1cyI6MCwiU2Vzc2lvblRva2VuIjoiYzYwZGMzM2VkNDliNjdkOTkzNzA2MGMyN2RjNDI4MDNlY2NmMTg0MDM5NzRjZWI3NGNiMjdlYzdmMmRiOGJmOSIsIklzRGVsZXRlZCI6MCwic2Vzc2lvbiI6NDYxMzA5fQ.r9GnSrVdZseleL-6fS0WO6efLjcoY01MIVLBPorqo1c'
};
if (params['token'] !== undefined && params['token'].length > 0) {
  localStorage.setItem('user-token', params['token']);
}

// if (params['lon'] !== undefined && params['lat'] !== undefined) {
//   localStorage.setItem('lon', params['lon']);
//   localStorage.setItem('lat', params['lat']);
// }

const ifAuthenticated = (to, from, next) => {
  // const token = localStorage.getItem('user-token') || ''

  // удалить строку
  const token = params.token;

  if (token.length > 0) {
    next();
  } else {
    next('/error');
  }
  next();
};

const routes = [
  {
    path: '/',
    component: Main,
    beforeEnter: ifAuthenticated,
    meta: {
      requiresAuth: true
    }
  },
  {
    path : '/error',
    component : Error,
    meta: {
      guest: true
    }
  },
];

export default new VueRouter({
    mode: 'hash',
    routes: routes
});
