<template>
  <div>
    <div class="head">
      Выберите топливо
    </div>
    <div
      class="d-flex justify-content-center pt-3"
      style="height: 390px"
    >
      <swiper
        ref="swiper"
        class="swiper-container"
        :options="swiperOptions"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          v-for="(fuel, f) in fuelList"
          :key="f"
          class="slide"
          :class="{ 'active-slide': selectedFuel === fuel, 'inactive-slide': selectedFuel !== fuel }"
          @click="selectFuel(fuel)"
        >
          <div class="content">
            <div :class="{ 'name': true, 'inactive-name': selectedFuel !== fuel }">
              {{ fuel.type }}
            </div>
            <div :class="{ 'price': true, 'inactive-price': selectedFuel !== fuel }">
              {{ fuel.price }} ₽
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <button
      class="button"
      @click="handleContinue"
    >
      Далее
    </button>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  name: 'FuelList',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    fuelList: {
      type: Array,
      default: () => []
    },
    onSelectFuel: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      selectedFuel: null,
      swiper: null,
      swiperOptions: {
        direction: 'vertical',
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 10,
        mousewheel: true,
        scrollbar: {draggable: true},
        grabCursor: true,
        slideToClickedSlide: true,
        initialSlide: 0,
        touchStartPreventDefault: true,
        touchMoveStopPropagation: true,
        freeMode: true,
      }
    }
  },
  mounted() {
    this.selectedFuel = this.fuelList[0];
  },
  methods: {
    selectFuel(fuel) {
      this.selectedFuel = fuel;
      const index = this.fuelList.indexOf(fuel);
      if (this.swiper) {
        this.swiper.slideTo(index);
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange(swiper) {
      this.updateSelectedFuel(swiper.activeIndex);
    },
    updateSelectedFuel(index) {
      this.selectedFuel = this.fuelList[index];
    },
    handleContinue() {
      if (this.selectedFuel) {
        this.onSelectFuel(this.selectedFuel);
      }
    },
  }
}
</script>

<style scoped>
  .head {
    color: #8A898E;
    font-size: 24px;
    margin-top: 36px;
  }

  .slide {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    cursor: pointer;
    width: 220px;
    max-height: 110px;
    min-height: 110px;
    align-items: center;
    transition: background 0.3s, color 0.3s;
    margin: 0 auto;
  }

  .active-slide {
    background: #F4F5FA;
  }

  .inactive-slide {
    background: white;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }

  .name {
    font-weight: 600;
    font-size: 32px;
    color: #222A34;
    line-height: 44px;
  }

  .inactive-name {
    color: #BEBEBE;
  }

  .price {
    font-weight: 400;
    font-size: 20px;
    color: #00A3FF;
  }

  .inactive-price {
    color: #BEBEBE;
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    padding: 0;
    border-radius: 16px;
    color: white;
    background: #00A3FF;
    height: 52px;
    max-width: 400px;
    min-width: 330px;
  }

  .swiper-container {
    width: 100vw;
  }
</style>
