<template>
  <div>
    <div
      class="price-content"
      :style="{ marginTop: isWriteBonuses ? '36px' : '70px' }"
    >
      <div
        v-if="isWriteBonuses && bonus > 0"
        class="sum"
      >
        {{ getFormattedValues.sum }}
      </div>
      <div class="price">
        {{ getFormattedValues.sumOrder }}
      </div>
      <div class="liters">
        {{ getFormattedValues.liters }} литров
      </div>
    </div>
    <div
      class="payment-info"
      style="margin: 0 auto"
    >
      <div
        class="d-flex justify-content-start"
        style="border-bottom: #E4E4E4 solid 1px; height: 34px; font-size: 16px; font-weight: 500;"
      >
        Баланс {{ balance }} ₽
      </div>
      <div
        class="d-flex justify-content-between"
        style="border-bottom: #E4E4E4 solid 1px"
      >
        <div>
          <div
            style="font-size: 16px; font-weight: 500; margin-top: 12px"
          >
            Бонусы {{ bonus }}
          </div>
          <div
            style="font-size: 16px; font-weight: 300; margin-bottom: 8px"
          >
            Списать всё
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <label class="checkbox-ios">
            <input
              v-model="isWriteBonuses"
              type="checkbox"
            >
            <span class="checkbox-ios-switch" />
          </label>
        </div>
      </div>
    </div>
    <div
      class="payment-content"
      :style="{ height: isWriteBonuses ? '230px' : '210px' }"
    >
      <div class="payment-content__center">
        <div style="border-bottom: #E4E4E4 solid 1px">
          <div class="d-flex justify-content-between payment-info mb-2">
            <div>{{ fuel.type }}, {{ getFormattedValues.liters }} л</div>
            <div>{{ getFormattedValues.sum }}</div>
          </div>
          <div
            v-if="isWriteBonuses && bonus > 0"
            class="d-flex justify-content-between payment-info"
            style="font-size: 16px; color: #8A898E; margin-bottom: 12px"
          >
            <div>Оплата бонусами</div>
            <div>- {{ bonusesApplied }}  ₽</div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between payment-info"
          style="font-size: 24px; font-weight: 500; margin-top: 6px"
        >
          <div>Итого</div>
          <div>{{ getFormattedValues.sumOrder }}</div>
        </div>
      </div>
    </div>
    <button
      class="button"
      @click="createOrder"
    >
      Оплатить {{ getFormattedValues.sumOrder }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'PaymentRefueling',
  props: {
    liters: {
      type: Number,
      default: null,
    },
    sum: {
      type: Number,
      default: null,
    },
    fuel: {
      type: Object,
      required: true,
    },
    paymentOrder: {
      type: Function,
      required: true
    },
  },
  data() {
  return {
    sumOrder: this.sum,
    balance: 2196,
    bonus: 347,
    bonusesApplied: 0,
    isWriteBonuses: false,
    orderId: 1,
  }
},
  computed: {
    getFormattedValues() {
      return {
        liters: `${this.liters.toFixed(2).replace(/\.00$/, '')}`,
        sum: `${this.sum.toFixed(2).replace(/\.00$/, '')} ₽`,
        sumOrder: `${this.sumOrder.toFixed(2).replace(/\.00$/, '')} ₽`,
      };
    },
  },
  watch: {
    isWriteBonuses: function(newVal) {
      if (newVal && this.bonus > 0) {
        let newPrice = this.sum - this.bonus;
        this.sumOrder = Math.max(newPrice, 1);
        this.sumOrder = Math.min(this.sumOrder, this.sum);
        this.bonusesApplied = Math.min(this.bonus, this.sum - this.sumOrder);
      } else {
        this.sumOrder = this.sum;
        this.bonusesApplied = 0;
      }
    }
  },
  methods: {
    createOrder() {
      this.paymentOrder(this.liters, this.sumOrder, this.orderId);
    }
  }
}
</script>

<style scoped>
  .price-content {
    margin-top: 70px;
    margin-bottom: 48px;
  }

  .price {
    font-size: 36px;
  }

  .sum {
    font-size: 24px;
    color: #8A898E;
    text-decoration: line-through;
  }

  .liters {
    font-size: 20px;
    font-weight: 400;
  }

  .payment-content {
    background: #F4F5FA;
    position: absolute;
    width: 100vw;
    height: 210px;
    left: 50%;
    bottom: -18%;
    transform: translate(-50%, -50%);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .payment-content__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
  }

  .payment-info {
    max-width: 340px;
    min-width: 330px;
  }

  .button {
    position: absolute;
    padding: 0;
    border: none;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    color: white;
    background: #00A3FF;
    height: 52px;
    max-width: 400px;
    min-width: 350px;
  }

  .checkbox-ios {
    display: inline-block;
    height: 30px;
    line-height: 28px;
    position: relative;
    vertical-align: middle;
    user-select: none;
    margin-bottom: 0;
  }
  .checkbox-ios .checkbox-ios-switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 50px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 30%/50%;
    vertical-align: top;
    background: #eee;
    transition: .2s;
  }
  .checkbox-ios .checkbox-ios-switch:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: white;
    transition: .2s;
  }
  .checkbox-ios input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .checkbox-ios input[type=checkbox]:checked + .checkbox-ios-switch {
    background: #00A3FF;
  }
  .checkbox-ios input[type=checkbox]:checked + .checkbox-ios-switch:before {
    transform:translateX(20px);
  }
</style>
