<template>
  <div
    class="station"
    :class="{ open: isOpen, closed: !isOpen }"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div
      class="d-flex justify-content-center mb-3"
      style="height: 14px; width: 100%"
    >
      <img
        class="swipe"
        src="@/assets/swipe.svg"
        alt=""
      >
    </div>
    <div class="d-flex justify-content-between">
      <div
        class="link d-flex justify-content-center"
        @click="clickButtonBack"
      >
        <img
          src="@/assets/back.svg"
          alt=""
          width="10"
          height="16"
          class="image-back"
        >
      </div>
      <div class="ml-1">
        <div class="name">
          {{ stationInformation.name }}
        </div>
        <div class="address">
          {{ stationInformation.address }}
        </div>
      </div>
      <div style="width: 40px" />
    </div>
    <button
      id="btnFuelOrder"
      class="button-refuel"
      @click="handleRefuel"
    >
      Заправиться здесь
    </button>
    <div
      class="d-flex justify-content-center flex-column overflow-hidden"
    >
      <div class="slide-container">
        <div
          v-for="(fuel, f) in fuels"
          :key="f"
          class="slide"
        >
          <div class="d-flex flex-column">
            <div class="type-name">
              АИ
            </div>
            <div class="type">
              {{ fuel.type }}
            </div>
          </div>
          <div class="sum">
            58.34 ₽
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StationInformation",
  props: {
    stationInformation: {
      type: Object,
      default: null
    },
    showStation: {
      type: Function,
      default: null
    },
    setSelected: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      swiper: null,
      isOpen: true,
    }
  },
  computed: {
    fuels() {
      if (this.stationInformation.fuel != null) {
        return this.stationInformation.fuel
      } else {
        return []
      }
    },
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      this.touchCurrentY = event.touches[0].clientY;
    },
    handleTouchEnd() {
      const touchDiff = this.touchStartY - this.touchCurrentY;
      const threshold = 50;
      this.isOpen = touchDiff > threshold;
    },
    handleRefuel() {
      this.showStation();
    },
    clickButtonBack() {
      this.setSelected(null);
    }
  }
}
</script>

<style scoped>
  .swipe{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .name {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .address {
    font-size: 16px;
    font-weight: 400;
    color: #222A34;
    text-align: center;
    margin-bottom: 24px;
  }

  .type-name {
    margin-top: 3px;
    font-size: 20px;
    color: #222A34;
    line-height: 24px;
  }

  .type {
    font-size: 14px;
    color: #8A898E;
  }

  .sum {
    margin: auto 0;
    font-size: 14px;
    color: #222A34;
  }

  .station {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 570px;
    background: white;
    padding: 0 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: bottom 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    z-index: 1001;
  }

  .button-refuel {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 16px;
    color: white;
    background: #00A3FF;
    height: 52px;
    max-width: 400px;
    z-index: 1001;
    min-width: 330px;
  }

  .station.open {
    bottom: 0;
  }

  .station.closed {
    bottom: -220px;
  }

  .slide-container {
    overflow-y: scroll;
    height: auto;
  }

  .slide {
    max-height: 65px;
    max-width: 360px;
    min-width: 330px;
    background: #F4F5FA;
    padding: 8px 16px;
    margin: 8px auto;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
  }

  .link {
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    min-width: 44px;
    height: 44px;
    background-color: #F4F5FA;
    border-radius: 50%;
  }

  .image-back {
    margin-top: 14px;
  }
</style>
