<template>
  <div>
    <div class="head">
      Выберите колонку
    </div>
    <div class="section">
      <div
        v-for="(fuelDispenser, k) in selectedFuelDispenser"
        :key="k"
        class="section__item"
      >
        <a
          class="link"
          @click="selectFuelDispenser(fuelDispenser)"
        >
          <div
            class="fuel-dispenser"
          >
            {{ fuelDispenser.name }}
          </div>
          <div
            v-for="(fuel, f) in fuelDispenser.fuel"
            :key="f"
            class="fuel-text"
          >
            {{ fuel.type }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectFuelDispenser',
  props: {
    kolonki: {
      type: Object,
      default: null
    },
    onSelectFuelDispenser: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      selectedFuelDispenser: []
    }
  },
  watch: {
    fuelDispenser(newFuelDispensers) {
      this.selectedFuelDispenser = newFuelDispensers.kolonki
    }
  },
  mounted() {
    if (this.kolonki !== null) {
      this.selectedFuelDispenser = this.kolonki.kolonki
    }
  },
  methods: {
    selectFuelDispenser(fuelDispensers) {
      this.onSelectFuelDispenser(fuelDispensers.id, fuelDispensers.fuel)
    }
  }
}
</script>

<style scoped>
  .head {
    color: #8A898E;
    font-size: 24px;
    margin-top: 36px;
    margin-bottom: 112px;
  }
  .section {
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0 0 30px 45vw;
    margin: 30px -10px;
    max-height: 336px;
    scroll-behavior: smooth;
  }
  .section__item {
    scroll-snap-align: start;
    flex: 0 0 auto;
    margin: 0 4px;
    background: #F4F5FA;
    border-radius: 20px;
    min-width: 100px;
    max-height: 210px;
  }
  .section::-webkit-scrollbar {
    display: none;
  }
  .fuel-dispenser {
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    text-align: center;
    color: #222A34;
    margin-bottom: 20px;
    margin-top: 24px;
  }
  .fuel-text {
    font-size: 16px;
    line-height: 14px;
    color: #00A3FF;
    margin-bottom: 8px;
  }
  .fuel-text:last-of-type {
    margin-bottom: 24px;
  }
  .link {
    text-decoration: none;
  }
  .link:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .section {
      padding: 0 0 30px 44vw;
    }
  }
  @media (max-width: 425px) {
    .section {
      padding: 0 0 30px 38vw;
    }
  }
  @media (max-width: 375px) {
    .section {
      padding: 0 0 30px 35vw;
    }
  }
</style>
