var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('yandex-map',{attrs:{"settings":_vm.settings,"coords":_vm.coords,"cluster-options":_vm.clusterOptions,"controls":['zoomControl']},on:{"boundschange":_vm.updateMapTimer}},[_c('ymap-marker',{attrs:{"coords":_vm.coords,"marker-id":"my","hint-content":"Моё местоположение","icon":{
        glyph: 'AutoCircle',
        color: 'red'
      }}}),_vm._l((_vm.stations),function(station){return _c('ymap-marker',{key:station.id,attrs:{"coords":station.coords,"marker-id":station.id,"hint-content":station.name,"icon":{
        glyph: 'FuelStationCircle',
        color: 'blue'
      },"cluster-name":"1","balloon":{layout: station.name, image: station.image, header: station.name, body: station.address}},on:{"click":function($event){return _vm.setSelected(station)},"balloonopen":_vm.bindListener,"balloonclose":_vm.unbindListener}})})],2),_c(_vm.getComponent,{tag:"component",attrs:{"station":_vm.stations,"selected-fuel-types":_vm.selectedFuelTypes,"selected-station-brands":_vm.selectedStationBrands,"station-information":_vm.selStation,"show-station":_vm.showStation,"set-selected":_vm.setSelected,"on-close-modal":_vm.onCloseModal,"order":_vm.order,"is-modal-visible":_vm.isModalVisible},on:{"updateSelectedFuelTypes":_vm.updateSelectedFuelTypes,"updateFilterShow":_vm.updateFilterShow,"updateSelectedStationBrands":_vm.updateSelectedStationBrands}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }