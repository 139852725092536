<template>
  <div>
    <div class="d-flex justify-content-center">
      <component
        :is="getImageByStatus"
        class="image"
      />
    </div>
    <div class="title">
      {{ getTitleOrderByStatus }}
    </div>
    <div
      v-if="this.$store.getters.STATE_ORDER === 'completed'"
      class="price-order"
    >
      - {{ this.$store.getters.STATE.sum }} ₽
    </div>
    <div
      class="subtitle"
    >
      {{ getSubtitleOrderByStatus }}
    </div>
    <button
      v-if="this.$store.getters.STATE_ORDER !== 'fueling'"
      class="button"
      :style="getButtonStyles"
      @click="actionFromOrder"
    >
      {{ getButtonNameByStatus }}
    </button>
  </div>
</template>

<script>
import ErrorFuelDispenser from "@/components/icon/ErrorFuelDispenser.vue";
import RefuelingProcess from "@/components/icon/RefuelingProcess.vue";
import SuccessFueling from "@/components/icon/SuccessFueling.vue";
import FuelDispenser from "@/components/icon/FuelDispenser.vue";
export default {
  name: "RefuelingStatus",
  components:{ RefuelingProcess, SuccessFueling, ErrorFuelDispenser, FuelDispenser },
  props: {
    onClosed: {
      type: Function,
      required: true,
    },
  },
  computed: {
    getImageByStatus() {
      const stateOrder = this.$store.getters.STATE_ORDER;
      switch (stateOrder) {
        case 'fueling':
          return RefuelingProcess;
        case 'completed':
          return SuccessFueling
        case 'cancelled_by_station':
        case 'cancelled_by_user':
          return ErrorFuelDispenser;
        default:
          return FuelDispenser
      }
    },
    getTitleOrderByStatus() {
      const stateOrder = this.$store.getters.STATE_ORDER;
      switch (stateOrder) {
        case 'fueling':
          return 'Идёт заправка';
        case 'completed':
          return 'Заправка завершена';
        case 'cancelled_by_station':
        case 'cancelled_by_user':
          return 'Заправка отменена';
        default:
          return 'Готово к заправке';
      }
    },
    getSubtitleOrderByStatus() {
      const stateOrder = this.$store.getters.STATE_ORDER;
      switch (stateOrder) {
        case 'completed':
          return this.$store.getters.STATE.liters + ' л';
        case 'cancelled_by_station':
          return 'К сожалению АЗС отменила вашу заправку';
        case 'cancelled_by_user':
          return 'Вы отменили заправку, деньги вернутся на баланс в течение двух дней';
        default:
          return 'Дождитесь заправщика или заправьтесь самостоятельно';
      }
    },
    getButtonNameByStatus() {
      const stateOrder = this.$store.getters.STATE_ORDER;
      switch (stateOrder) {
        case 'completed':
          return 'На главную';
        case 'cancelled_by_station':
          return 'Попробовать заново';
        case 'cancelled_by_user':
          return 'Вернуться на главную';
        default:
          return 'Отменить';
      }
    },
    getButtonBackgroundColor() {
      const buttonName = this.getButtonNameByStatus;
      return buttonName === 'Отменить' ? '#F4F5FA' : '#00A3FF';
    },
    getButtonTextColor() {
      return this.getButtonBackgroundColor === '#F4F5FA' ? '#222A34' : 'white';
    },
    getButtonStyles() {
      return {
        backgroundColor: this.getButtonBackgroundColor,
        color: this.getButtonTextColor,
      };
    },
  },
  methods: {
    actionFromOrder() {
      // удалить первый вызов this.onClosed()
      this.onClosed();
      const stateOrder = this.$store.getters.STATE_ORDER;
      switch (stateOrder) {
        case 'completed':
          return this.onClosed;
        case 'cancelled_from_station':
          return 'Попробовать заново';
        case 'cancelled_from_user':
          return this.onClosed;
        default:
          return this.onClosed;
      }
    }
  }
}
</script>

<style scoped>
  .image {
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .price-order {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
  }

  .subtitle {
    font-weight: 300;
    max-width: 400px;
    margin: 8px auto;
  }

  .button {
    position: absolute;
    padding: 0;
    border: none;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    height: 52px;
    max-width: 400px;
    min-width: 330px;
  }
</style>
