<template>
  <div>
    <div
      id="fuel-modal"
      class="fuel-amount"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div
        class="information-block"
        :style="getBackgroundColor"
        :class="{ open: isOpen, closed: !isOpen }"
      >
        <div
          class="d-flex justify-content-center mb-3"
          style="height: 14px; width: 100%"
        >
          <img
            class="image-swipe"
            :src="getSwipeImageByStatus"
            alt="Свайп"
          >
        </div>
        <div class="d-flex justify-content-between">
          <div
            v-if="orderId === 0"
            class="link d-flex justify-content-center"
            @click="clickButtonBack"
          >
            <img
              src="@/assets/back.svg"
              alt="Кнопка назад"
              width="10"
              height="16"
              class="image-back"
            >
          </div>
          <div
            v-if="orderId > 0"
            style="width: 40px"
          />
          <div class="ml-1">
            <div>
              <div
                class="title"
                :style="getTextColor"
              >
                {{ getTitle }}
              </div>
              <div
                class="subtitle"
                :style="getTextColor"
              >
                {{ getSubtitle }}
              </div>
            </div>
          </div>
          <div style="width: 40px" />
        </div>
        <div
          v-if="order === null"
          class="text-center"
        >
          <template>
            <div
              v-if="loading"
              class="loader-wrapper"
            >
              <div class="loader">
                <img
                  class="loader-img"
                  src="@/assets/gas.svg"
                  alt="Автозаправочная колонка"
                >
                <img
                  class="loader-img"
                  src="@/assets/gas.svg"
                  alt="Автозаправочная колонка"
                >
                <img
                  class="loader-img"
                  src="@/assets/gas.svg"
                  alt="Автозаправочная колонка"
                >
              </div>
            </div>
            <div v-else>
              <component
                :is="getComponent"
                :kolonki="getFuelDispensers"
                :fuel-list="fuelList"
                :liters="liters"
                :sum="sum"
                :fuel="fuel"
                :is-accepted="isAccepted"
                :sum-order="sumOrder"
                :order-id="orderId"
                :on-select-fuel-dispenser="onSelectFuelDispenser"
                :on-select-fuel="onSelectFuel"
                :on-accepted="onAccepted"
                :op-payment="opPayment"
                :payment-order="paymentOrder"
                :on-closed="onClosed"
              />
            </div>
          </template>
        </div>
        <div
          v-else
          class="text-center"
        >
          <RefuelingStatus
            v-if="order !== null"
            :order-id="order.id"
            :liters="order.liters"
            :fuel="order.fuel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectFuelDispenser from './SelectFuelDispenser.vue';
import FuelList from './FuelList.vue';
import FuelAmountSelector from "./FuelAmountSelector.vue";
import PaymentRefueling from "./PaymentRefueling.vue";
import RefuelingStatus from "./RefuelingStatus.vue";
import FuelingWarning from  "./FuelingWarning.vue"

export default {
  name: 'ProcessRefuelingCar',
  components: { SelectFuelDispenser, FuelList, FuelAmountSelector, PaymentRefueling, RefuelingStatus, FuelingWarning },
  props: {
    stationInformation: {
      type: Object,
      default: null
    },
    onCloseModal: {
      type: Function,
      default: null
    },
    order: {
      type: Object,
      default: null
    },
    isModalVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      stationSel: this.stationInformation,
      fuelDispenser: 0,
      fuel: null,
      fuelList: [],
      loading: true,
      liters: 0,
      sum: 0,
      sumOrder: 0,
      orderId: 0,
      isOpen: true,
      touchStartY: 0,
      touchCurrentY: 0,
      isAccepted: false,
    }
  },
  computed: {
    getFuelDispensers() {
      if (this.$store.getters.KOLONKI !== undefined) {
        return this.$store.getters.KOLONKI
      }
      return null
    },
    // order заменить на order.status
    getTitle() {
      if (this.liters > 0 && this.orderId === 0 || this.order === 'cancelled' || this.order === 'failed') {
        return 'Оплата';
      }
      if (this.stationSel) {
        if (this.fuelDispenser > 0 || this.orderId > 0) {
          return this.fuel ? this.fuel.type : `${this.fuelDispenser} колонка`;
        }
        return this.stationSel.name;
      }
      return '';
    },
    // order заменить на order.status
    getSubtitle() {
      if (this.liters > 0 && this.orderId === 0 || this.fuel !== null && this.isAccepted === false) {
        return `${this.fuel.type}, ${this.fuelDispenser} колонка`;
      }
      if (this.stationSel) {
        if (this.fuelDispenser > 0 || this.orderId > 0) {
          return this.fuel ? `${this.fuelDispenser} колонка` : this.stationSel.name;
        }
        return this.stationSel.address;
      }
      if (this.order === 'cancelled' || this.order === 'failed') {
        return `${this.liters} литров, ${this.fuel.type}`
      }
      return '';
    },
    getComponent() {
      switch (true) {
        case this.orderId > 0:
          return 'RefuelingStatus';
        case this.liters > 0 && this.sum > 0:
          return 'PaymentRefueling';
        case  this.fuel !== null && this.isAccepted === false:
          return 'FuelingWarning'
        case this.fuel !== null && this.liters === 0 && this.sum === 0 && this.isAccepted === true:
          return 'FuelAmountSelector';
        case this.fuelDispenser > 0 && this.fuel === null:
          return 'FuelList';
        default:
          return 'SelectFuelDispenser';
      }
    },
    // order заменить на order.status
    getSwipeImageByStatus() {
      if (this.order === 'fueling') {
        // eslint-disable-next-line no-undef
        return require('@/assets/swipe_white.svg');
      }
      // eslint-disable-next-line no-undef
      return require('@/assets/swipe.svg');
    },
    // order заменить на order.status
    getTextColor() {
      return this.order ? { color: 'white' } : { color: 'black' };
    },
    // order заменить на order.status
    getBackgroundColor() {
      return this.order ? { background: 'linear-gradient(360deg, #6E24CC -25.61%, #02A1FA 100%)' } : { background: 'white' };
    },
  },
  watch: {
    station(newStation) {
      this.stationSel = newStation
      if (this.stationSel) {
        this.onShow();
      }
    }
  },
  mounted() {
    if (this.isModalVisible) {
      this.onShow();
    }
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'fuel-modal') {
        this.onClosed()
      }
    })
  },
  methods: {
    async loadData () {
      this.loading = true
      this.$store.dispatch('GET_KOLONKI',{
        id: this.stationSel.id
      }).then().catch(() => {
        // обработка ошибок
      }).finally(()=> {
        this.loading = false
      })
    },
    onSelectFuelDispenser(id, fuel) {
      this.fuelDispenser = id;
      this.fuelList = fuel
    },
    onSelectFuel(fuel) {
      this.fuel = fuel;
    },
    opPayment(liters, sum) {
      this.liters = liters;
      this.sum = sum
    },
    onAccepted(isAccepted) {
      this.isAccepted = isAccepted
    },
    onShow() {
      this.fuelDispenser = 0
      this.fuel = null
      this.fuelList = []
      this.orderId = 0
      this.liters = 0
      this.loading = true
      if (this.stationSel != null) {
        this.loadData()
      }
    },
    onClosed() {
      this.fuelDispenser = 0
      this.fuel = null
      this.fuelList = []
      this.orderId = 0
      this.liters = 0
      this.$store.dispatch('RESET_STATEORDER')
      this.$store.dispatch('RESET_KOLONKI')

      this.onCloseModal()
    },
    clickButtonBack() {
      switch (true) {
        case (this.liters > 0 || this.sum > 0):
          this.clearPaymentDetails();
          break;
        case (this.isAccepted):
          this.clearIsAccepted();
          break;
        case (this.fuel !== null):
          this.clearSelectedFuel();
          break;
        case (this.fuelDispenser > 0):
          this.clearSelectedFuelDispenser();
          break;
        default:
          this.closeModal();
          break;
      }
    },
    clearPaymentDetails() {
      this.liters = 0;
      this.sum = 0;
      this.isOpen = true;
    },
    clearSelectedFuel() {
      this.fuel = null;
      this.isOpen = true;
    },
    clearSelectedFuelDispenser() {
      this.fuelDispenser = 0;
      this.isOpen = true;
    },
    clearIsAccepted() {
      this.isAccepted = false;
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'fuel-modal');
      this.onClosed();
    },
    paymentOrder(liters, sumOrder, orderId) {
      this.liters = liters;
      this.sumOrder = sumOrder;
      this.orderId = orderId;
    },
    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      this.touchCurrentY = event.touches[0].clientY;
    },
    handleTouchEnd() {
      const touchDiff = this.touchStartY - this.touchCurrentY;
      const threshold = 50;
      this.isOpen = touchDiff > threshold;
    },
  }
}
</script>

<style scoped>
  .image-swipe {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .link {
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    min-width: 44px;
    height: 44px;
    background-color: #F4F5FA;
    border-radius: 50%;
  }

  .image-back {
    margin-top: 14px;
  }

  .loader-wrapper {
    margin: 220px auto 0;
  }

  .loader {
    margin: 0 auto;
  }

  .loader-img {
    width: 70px;
    height: 70px;
    animation: bounce 1.5s infinite ease-in-out;
  }

  .loader-img:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loader-img:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }

  .fuel-amount {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 200vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1001;
  }

  .information-block {
    position: fixed;
    bottom: -100%;
    width: 100vw;
    z-index: 1001;
    padding: 0 16px 16px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: bottom 0.3s ease-in-out;
    height: 638px;
  }

  .information-block.open {
    bottom: 0;
  }

  .information-block.closed {
    bottom: -60%;
  }
</style>
