import axios from "axios";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
};

const getters = {
  KOLONKI: state => state.kolonki
};
const mutations = {
  SET_KOLONKI: (state, payload) => {
    state.kolonki = payload.data;
  },
};
const actions = {
  GET_KOLONKI: async (context, payload) => {
    const req = JSON.stringify(payload);
    let {data} = await axios.post('/fuel/kolonki/'+payload.id, req, config);
    context.commit('SET_KOLONKI', data);
    return data;
  },
  RESET_KOLONKI: async (context) => {
    context.commit('SET_KOLONKI', {'data': null});
  }
};

export default {
  state: () => ({
    kolonki: null,
  }),
  mutations,
  actions,
  getters
};
