import axios from "axios";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  timeout: 30000
};

const getters = {
  ORDER: state => state.order,
  BALANCE: state => state.balance
};
const mutations = {
  SET_CREATE_ORDER: (state, payload) => {
    state.order = payload.id;
  },
  SET_BALANCE: (state, payload) => {
    state.balance = payload.data;
  },
};
const actions = {
  GET_BALANCE: async (context, payload) => {
    const req = JSON.stringify(payload);
    let {data} = await axios.post('/fuel/balance', req, config);
    context.commit('SET_BALANCE', data);
    return data;
  },
  CREATE_ORDER: async (context, payload) => {
    const req = JSON.stringify(payload);
    let {data} = await axios.post('/fuel/create-order', req, config);
    context.commit('SET_CREATE_ORDER', data);
    return data;
  }
};

export default {
  state: () => ({
    order: null,
    balance: null,
  }),
  mutations,
  actions,
  getters
};
