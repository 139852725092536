<template>
  <div>
    <div class="d-flex justify-content-center fuel-dispenser">
     <FuelDispenser />
    </div>
    <div class="centered-text">
      Не снимайте пистолет&#10; до оплаты
    </div>
    <div style="font-weight: 300;">
      Сначала нужно оплатить, потом заправляться
    </div>
    <button
      class="button"
      @click="goToFuelAmount"
    >
      Хорошо
    </button>
  </div>
</template>

<script>
import FuelDispenser from "@/components/icon/FuelDispenser.vue";

export default {
  name: "FuelingWarning",
  components: {FuelDispenser},
  props: {
    isAccepted: {
      type: Boolean,
      default: null,
    },
    onAccepted: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      localIsAccepted: this.isAccepted,
    };
  },
  methods: {
    goToFuelAmount() {
      this.localIsAccepted = true;
      this.onAccepted(this.localIsAccepted)
    }
  }
}
</script>

<style scoped>
  .fuel-dispenser {
    margin-top: 70px;
    margin-bottom: 16px;
  }

  .button {
    position: absolute;
    padding: 0;
    border: none;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    color: white;
    background: #00A3FF;
    height: 52px;
    max-width: 400px;
    min-width: 330px;
  }

  .centered-text {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    width: 300px;
    white-space: pre-wrap;
    margin: 0 auto 4px;
    line-height: 1.2;
  }
</style>
