<template>
  <div>
    <div class="button-filter">
      <ButtonFilter
        v-if="getCurrentState === 'closed' && showButtonFilter"
        :selected-fuel-types="selectedFuelTypes"
        :is-filter-show="isFilterShow"
        :selected-station-brands="selectedStationBrands"
        @selectedFuelTypes="updateSelectedFuelTypes"
        @isFilterShow="updateFilterShow"
      />
    </div>
    <div
      class="station-list"
      :class="getCurrentState"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div
        class="d-flex justify-content-center mb-3"
        style="height: 14px; width: 100%"
      >
        <img
          class="image-swipe"
          src="@/assets/swipe.svg"
          alt="Свайп"
        >
      </div>
      <ButtonFilter
        v-if="getCurrentState !== 'closed'"
        :selected-fuel-types="selectedFuelTypes"
        :is-filter-show="isFilterShow"
        :selected-station-brands="selectedStationBrands"
        @selectedFuelTypes="updateSelectedFuelTypes"
        @isFilterShow="updateFilterShow"
      />
      <div
        v-if="getCurrentState !== 'closed'"
        class="tab-container"
      >
        <button
          class="tab-filter"
          :class="{ 'active': selectedFilter === 'distance' }"
          @click.stop="setActiveFilter('distance')"
        >
          По удаленности
        </button>
        <button
          class="tab-filter"
          :class="{ 'active': selectedFilter === 'price' }"
          @click.stop="setActiveFilter('price')"
        >
          По цене
        </button>
      </div>
      <div
        class="scroll-container"
        :class="getCurrentState"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <div class="scroll-list">
          <div
            v-for="(station, s) in station"
            :key="s"
            class="slide"
          >
            <div class="d-flex">
              <div class="mr-2">
                <img
                  src="@/assets/gasprom.svg"
                  alt="Газпром"
                >
              </div>
              <div>
                <div>{{ station.name }}</div>
                <div style="font-size: 14px; color:#8A898E;">
                  123.34 км
                </div>
              </div>
            </div>
            <div>62 ₽</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFilter from "@/components/ButtonFilter.vue";

export default {
  name: "StationList",
  components: {
    ButtonFilter,
  },
  props: {
    selectedFuelTypes: {
      type: Array,
      default: () => [],
    },
    station: {
      type: Array,
      default: null,
    },
    selectedStationBrands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      isMiddle: false,
      touchStartY: 0,
      touchCurrentY: 0,
      selectedFilter: 'distance',
      isFilterShow: false,
      isSwipe: false,
      showButtonFilter: true,
    };
  },
  computed: {
    getCurrentState() {
      if (this.isOpen) return 'open';
      if (this.isMiddle) return 'middle';
      return 'closed';
    },
  },
  methods: {
    setActiveFilter(filter) {
      this.selectedFilter = filter;
    },
    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
      this.isSwipe = false;
    },
    handleTouchMove(event) {
      this.touchCurrentY = event.touches[0].clientY;
      this.isSwipe = Math.abs(this.touchStartY - this.touchCurrentY) > 10;
    },
    handleTouchEnd() {
      if (!this.isSwipe) return;
      const touchDiff = this.touchStartY - this.touchCurrentY;
      const thresholdOpen = 200;
      const thresholdMiddle = 100;
      this.isOpen = touchDiff > thresholdOpen;
      this.isMiddle = touchDiff > thresholdMiddle;
      this.updateShowButtonFilter();
    },
    updateSelectedFuelTypes(selectedFuelTypes) {
      this.$emit('updateSelectedFuelTypes', selectedFuelTypes);
    },
    updateFilterShow(isFilterShow) {
      this.$emit('updateFilterShow', isFilterShow);
    },
    updateShowButtonFilter() {
      this.showButtonFilter = false;
      setTimeout(() => {
        this.showButtonFilter = true;
      }, 300);
    },
    onTouchStart(event) {
      event.stopPropagation();
    },
    onTouchMove(event) {
      event.stopPropagation();
    },
    onTouchEnd(event) {
      event.stopPropagation();
    },
  },
}
</script>

<style scoped>
  .button-filter {
    position: fixed;
    z-index: 1002;
    width: 100vw;
    bottom: 19vh;
  }

  .station-list {
    height: 18vh;
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: height 0.3s ease-in-out;
    background: white;
    z-index: 1002;
    padding: 0 16px 16px;
  }

  .station-list.closed {
    height: 18vh;
  }

  .station-list.middle {
    height: 40vh;
  }

  .station-list.open {
    height: 100vh;
  }

  .image-swipe {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .tab-container {
    display: flex;
    justify-content: space-between;
    background: #f4f5fa;
    height: 50px;
    max-width: 400px;
    margin: 20px auto 30px;
    border-radius: 16px;
  }

  .tab-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    height: 44px;
    border: 0 solid;
    background: #f4f5fa;
    color: #8a898e;
    width: 48%;
    margin: auto;
  }

  .tab-filter.active {
    background: white;
    color: #00a3ff;
  }

  .scroll-container {
    overflow-y: scroll;
    transition: height 0.3s ease-in-out;
    height: calc(18vh - 40px);
  }

  .scroll-container.middle {
    height: calc(40vh - 180px);
  }

  .scroll-container.open {
    height: calc(100vh - 180px);
  }

  .slide {
    max-width: 400px;
    height: 60px;
    margin: 0 auto 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
