<template>
  <div style="max-width: 360px; margin: 0 auto;">
    <div
      class="mt-2"
      style="font-size: 16px; color: #8A898E;"
    >
      Баланс {{ balance }} ₽ и {{ bonus }} баллов
    </div>

    <div class="d-flex justify-content-between align-items-center mb-2 mt-2 position-relative">
      <button
        class="btn-reduce"
        @click="changeValue('liters', false, 10)"
      >
        -10
      </button>
      <input
        v-model="getFormattedValues.liters"
        type="text"
        class="form-control text-center input-value"
        readonly
        :class="{'active': activeInput === 'liters', 'readonly': true}"
        @focus="setActiveInput('liters')"
      >
      <button
        class="btn-add"
        @click="changeValue('liters', true, 10)"
      >
        +10
      </button>
    </div>

    <div class="d-flex justify-content-between align-items-center position-relative">
      <button
        class="btn-reduce"
        @click="changeValue('sum', false, 500)"
      >
        -500
      </button>
      <input
        v-model="getFormattedValues.sum"
        type="text"
        class="form-control text-center input-value"
        readonly
        :class="{'active': activeInput === 'sum', 'readonly': true}"
        @focus="setActiveInput('sum')"
      >
      <button
        class="btn-add"
        @click="changeValue('sum', true, 500)"
      >
        +500
      </button>
    </div>

    <div class="position-relative mt-1">
      <div
        v-if="isMessageFullTankVisible"
        class="position-absolute"
        style="font-size: 16px; color: #8A898E; top: 0"
      >
        60 литров если бак меньше - вернем разницу
      </div>
      <div
        v-if="isErrorMessageVisible"
        class="position-absolute"
        style="font-size: 16px; color: #FF3333; top: 0"
      >
        Не хватает средств, уменьшите сумму или пополните баланс в приложении
      </div>
    </div>

    <div class="row mb-0 number-form">
      <div
        v-for="number in numbers"
        :key="number"
        class="col-4 d-flex justify-content-center align-items-center"
      >
        <button
          :class="{'btn btn-light w-100 pt-2 pb-2': number !== 0}"
          class="number"
          @click="updateInput(number)"
        >
          {{ number }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-center align-items-center">
        <button
          class="button-full-tank"
          @click="fillFullTank"
        >
          Полный бак
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center align-items-center">
        <button
          class="btn btn-light w-100 pt-2 pb-2 button-zero"
          @click="updateInput(0)"
        >
          0
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center align-items-center">
        <button
          class="button-clear"
          @click="clearInput"
        >
          <img
            src="@/assets/clear.svg"
            alt="Очистить"
            width="26"
            height="20"
          >
        </button>
      </div>
    </div>

    <b-button
      :disabled="isButtonDisabled"
      class="button"
      @click="onPaymentRefueling"
    >
      К оплате
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'FuelAmountSelector',
  props: {
    fuel: {
      type: Object,
      required: true
    },
    opPayment: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      loading: false,
      store: {
        liters: 0,
        sum: 0
      },
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      activeInput: null,
      balance: 2196,
      bonus: 347,
      maxLiters: 100,
    }
  },

  computed: {
    sum: {
      get() {
        return this.store.sum;
      },
      set(val) {
        const maxSum = this.maxLiters * this.fuel.price;
        this.store.sum = Math.min(val, maxSum);
        this.store.liters = Math.floor(this.store.sum / this.fuel.price * 100.0) / 100.0;
      },
    },
    liters: {
      get() {
        return this.store.liters;
      },
      set(val) {
        if (val > this.maxLiters) {
          this.store.liters = this.maxLiters;
        } else {
          this.store.liters = val;
        }
        this.store.sum = Math.round(this.store.liters * this.fuel.price * 100.0) / 100.0;
      },
    },
    getFormattedValues() {
      return {
        liters: `${this.liters.toFixed(2).replace(/\.00$/, '')} л`,
        sum: `${this.sum.toFixed(2).replace(/\.00$/, '')} ₽`
      };
    },
    isButtonDisabled() {
      return this.isErrorMessageVisible || this.liters === 0 || this.sum === 0;
    },
    isErrorMessageVisible() {
      return this.sum > this.balance + this.bonus;
    },
    isMessageFullTankVisible() {
      return !this.isErrorMessageVisible && this.liters === 60;
    },
  },
  watch: {
    liters() {
      this.updateButtonState();
    },
    sum() {
      this.updateButtonState();
    },
    fuel(newFuel) {
      this.sum = Math.round(this.liters * newFuel.price * 100.0) / 100.0;
    },
  },
  methods: {
    fillFullTank() {
      this.liters = 60;
    },
    changeValue(field, increment, step) {
      this.activeInput = field;
      this[field] = Math.max(0, parseFloat(this[field]) + (increment ? step : -step));
    },
    clearInput() {
      const value = this[this.activeInput];
      this[this.activeInput] = value.toString().length > 1
        ? parseFloat(value.toString().slice(0, -1)) || 0
        : 0;
    },
    updateInput(value) {
      const currentValue = this[this.activeInput];
      this[this.activeInput] = currentValue === 0
        ? value
        : (currentValue * 10) + value;
    },
    setActiveInput(input) {
      this.activeInput = input;
    },
    updateButtonState() {
      this.$forceUpdate();
    },
    onPaymentRefueling() {
      this.opPayment(this.liters, this.sum);
    }
  }
}
</script>

<style scoped>
  .button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    padding: 0;
    border-radius: 16px;
    color: white;
    background: #00A3FF;
    height: 52px;
    max-width: 400px;
    min-width: 330px;
  }

  .number {
    background: none;
    border: 0 solid;
    font-size: 32px;
  }

  .button-zero {
    display: flex;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    background: none;
    border: 0 solid;
  }

  .button-full-tank {
    background: none;
    border: 0 solid;
    font-size: 16px;
    color: #222A34
  }

  .button-clear {
    background: none;
    border: 0 solid;
  }

  .row {
    margin-bottom: 1rem;
  }

  .btn-add {
    position: absolute;
    right: 10px;
    height: 58px;
    width: 80px;
    background: none;
    border: 0 solid;
    font-size: 16px;
    color: #8A898E;
  }

  .input-value {
    height: 58px;
    width: 100%;
    background: #F4F5FA4D;
    border: 0 solid;
    border-radius: 20px;
    text-align: center;
  }

  .input-value.active {
    background: #F4F5FA;
    font-size: 24px;
    box-shadow: none;
  }

  .input-value:not(.active) {
    background: #F4F5FA4D;
  }

  .btn-reduce {
    position: absolute;
    left: 10px;
    height: 58px;
    width: 80px;
    background: none;
    border: 0 solid;
    font-size: 16px;
    color: #8A898E;
  }

  .number-form {
    margin-top: 45px;
  }

  .button:disabled,
  .button:disabled:hover,
  .button:disabled:focus,
  .button:disabled:active {
    background: #00A3FF;
  }
</style>
