<template>
  <div>
    <yandex-map
      :settings="settings"
      :coords="coords"
      :cluster-options="clusterOptions"
      :controls="['zoomControl']"
      @boundschange="updateMapTimer"
    >
      <ymap-marker
        :coords="coords"
        marker-id="my"
        hint-content="Моё местоположение"
        :icon="{
          glyph: 'AutoCircle',
          color: 'red'
        }"
      />
      <ymap-marker
        v-for="(station) in stations"
        :key="station.id"
        :coords="station.coords"
        :marker-id="station.id"
        :hint-content="station.name"
        :icon="{
          glyph: 'FuelStationCircle',
          color: 'blue'
        }"
        cluster-name="1"
        :balloon="{layout: station.name, image: station.image, header: station.name, body: station.address}"
        @click="setSelected(station)"
        @balloonopen="bindListener"
        @balloonclose="unbindListener"
      />
    </yandex-map>

    <component
      :is="getComponent"
      :station="stations"
      :selected-fuel-types="selectedFuelTypes"
      :selected-station-brands="selectedStationBrands"
      :station-information="selStation"
      :show-station="showStation"
      :set-selected="setSelected"
      :on-close-modal="onCloseModal"
      :order="order"
      :is-modal-visible="isModalVisible"
      @updateSelectedFuelTypes="updateSelectedFuelTypes"
      @updateFilterShow="updateFilterShow"
      @updateSelectedStationBrands="updateSelectedStationBrands"
    />
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import StationList from "./StationList.vue"
import FilterStation from "./FilterStation.vue"
import StationInformation from "./StationInformation.vue"
import ProcessRefuelingCar from "./ProcessRefuelingCar.vue"

export default {
  name: 'YandexMapFuel',
  components: { yandexMap, ymapMarker },
  data () {
    return {
      filterOpen: false,
      settings: {
        apiKey: '07e4480c-4b4c-4e27-add8-39d5b93ca5b4',
        lang: 'ru_RU',
        coordOrder: 'latlon',
        enterprise: false,
        version: '2.1'
      },
      coords: this.getInitialCoords(),
      radius: 0.1,
      clusterOptions: {
        1: {
          gridSize: 64,
          clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: true,
          clusterBalloonLayout: [
            '<div class="list">',
            '{% for geoObject in properties.geoObjects %}',
            '<div><a href=# data-action="geoObject" data-id="{{ geoObject.properties.balloonContentFooter|raw }}" class="list_item">{{ geoObject.properties.balloonContentHeader|raw }}</a></div>',
            '{% endfor %}',
            '</div>',
          ].join('')
        }
      },
      selStation: null,
      interval: null,
      updateTimer: null,
      isStationSelect: 0,
      order: null,
      fuelBrand: null,
      fuelStation: null,
      isModalVisible: false,
      isFilterShow: false,
      selectedFuelTypes: [],
      selectedStationBrands: [],
    }
  },
  computed: {
    stations() {
      if (this.$store.getters.STATIONS !== null) {
        return this.$store.getters.STATIONS
      }
      return null
    },
    getComponent() {
      if (this.isModalVisible) {
        return ProcessRefuelingCar;
      } else if (this.selStation) {
        return StationInformation;
      } else if (this.isFilterShow) {
        return FilterStation;
      } else {
        return StationList;
      }
    },
  },
  mounted() {
    this.mountApp()
    document.addEventListener("click", e => {
      if (e.target.dataset.action !== undefined && e.target.dataset.action === 'geoObject') {
        const id = e.target.dataset.id
        let station
        if (this.stations.some((val) => {
          if (id === val.id) {
            station = val
            return true
          }
          return false
        })) {
          this.setSelected(station)
          this.showStation()
        }
      }
    })
  },
  beforeDestroy() {
    this.stopTimer()
  },
  methods: {
    getInitialCoords() {
      const lon = localStorage.getItem('lon');
      const lat = localStorage.getItem('lat');
      if (lon && lat) {
        return [parseFloat(lat), parseFloat(lon)];
      }
      return [55.755826, 37.6173];
    },
    async mountApp() {
      await this.loadData()
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('GET_CHECKSESSION', {})
        .then((data) => {
          if (data.status === 1 && data.order != null) {
            this.showOrder(data.order)
          } else {
            this.startTimer()
          }
        })
        .catch(() => {
          this.$router.push('/error')
        })
      await this.loadStations()
    },
    async loadStations() {
      this.loading = true
      await this.$store.dispatch('GET_STATIONS', {
        x: this.coords[0],
        y: this.coords[1],
        radius: this.radius,
        brandname: this.fuelBrand
      })
    },
    bindListener() {
      document.getElementById('btnFuelOrder').addEventListener('click', this.showStation);
    },
    unbindListener() {
      const btnFuelOrder = document.getElementById('btnFuelOrder');
      if (btnFuelOrder) {
        btnFuelOrder.removeEventListener('click', this.showStation);
      }
    },
    setSelected(station) {
      if (station && station.id) {
        this.isStationSelect = station.id;
        this.selStation = station;
      } else {
        this.isStationSelect = 0;
        this.selStation = null;
      }
    },
    showStation() {
      this.stopTimer()
      this.isModalVisible = true
      this.$root.$emit('bv::show::modal', 'fuel-modal', '#btnShow')
    },
    updateMapTimer(e) {
      const b = e.originalEvent.map._bounds
      if (b !== undefined) {
        const x = b[1][0] - b[0][0];
        const y = b[1][1] - b[0][1];
        if (x > 0.1 || y > 0.1) {
          this.radius = x > y ? x : y
        } else {
          this.radius = 0.1
        }
      }
      this.stopUpdateMap()
      this.updateTimer = window.setTimeout(() => this.loadStations(), 3000)
    },
    stopUpdateMap() {
      if (this.updateTimer != null) {
        window.clearTimeout(this.updateTimer)
        this.updateTimer = null
      }
    },
    stopTimer() {
      if (this.interval != null) {
        window.clearInterval(this.interval)
        this.interval = null
      }
    },
    startTimer() {
      this.stopTimer()
      this.interval = window.setInterval(() => this.getCoordinates(), 5000)
    },
    async getCoordinates() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.coords = [position.coords.latitude, position.coords.longitude];
          this.isStationDone(position.coords.latitude, position.coords.longitude)
        })
      }
    },
    onCloseModal() {
      this.startTimer()
      this.isModalVisible = false
    },
    isStationDone(x, y) {
      if (this.stations !== null) {
        for (const station of this.stations) {
          if (Math.abs(station.coords[0] - x) < 0.000005 &&
            Math.abs(station.coords[1] - y) < 0.000005 &&
            station.id !== this.isStationSelect
          ) {
            this.setSelected(station)
            this.showStation()
            break;
          }
        }
      }
    },
    showOrder(order) {
      this.order = order
      this.showStation()
    },
    updateSelectedFuelTypes(selectedFuelTypes) {
      this.selectedFuelTypes = selectedFuelTypes;
    },
    updateFilterShow(isFilterShow) {
      this.isFilterShow = isFilterShow;
    },
    updateSelectedStationBrands(selectedStationBrands) {
      this.selectedStationBrands = selectedStationBrands;
    }
  }
}
</script>
