import axios from "axios";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
};

const getters = {
  STATIONS: state => state.stations, //getStations
};
const mutations = {
  SET_STATIONS: (state, payload) => {
      state.stations = payload.data;
  },
};
const actions = {
  GET_STATIONS: async (context, payload) => {
      const req = JSON.stringify(payload);
      let {data} = await axios.post('/fuel/stations', req, config);
      context.commit('SET_STATIONS', data);
      return data;
  }
};

export default {
    state: () => ({
      stations: null,
    }),
    mutations,
    actions,
    getters
};
