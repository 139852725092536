<template>
  <h2 class="text-center">
    Сессия не активна
  </h2>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>
